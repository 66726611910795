//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["selectList", "selectValue","text","isEnd"],
  data() {
    return {
      selectanswer: this.selectValue,
    };
  },
  watch: {
    selectanswer(newval) {
      this.$emit("changeSelectAnswer", newval);
    },
  },
};
