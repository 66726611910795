//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as API from "@/api/apiService.js";
export default {
  data() {
    return {
      claiminfo: "",
      disabled: false,
      evaluateinfo: {
        score: null,
        status: 1,
      },
    };
  },
  mounted() {
    this.claiminfo = JSON.parse(window.sessionStorage.getItem("claiminfo"));
    this.getEvaluateinfo();
  },
  methods: {
    getEvaluateinfo() {
      var formData = new FormData();
      formData.append("claimNumber", this.claiminfo.claimNumber);
      API.evaluateinfo(formData)
        .then((res) => {
          if (res.id != -1) {
            this.evaluateinfo = res;
            this.evaluateinfo.score = parseInt(this.evaluateinfo.score);
            this.disabled = true;
          } else {
            this.evaluateinfo = {};
            this.disabled = false;
          }
        })
        .catch((res) => {
          console.log(res);
          this.disabled = false;
        });
    },
    backDetail() {
      this.$router.push({ name: "ServiceDetail" });
    },
    saveEvaluate() {
      var param = {
        status: this.evaluateinfo.status,
        serviceTime: this.claiminfo.claimRepairDate,
        score: this.evaluateinfo.score.toString(),
        policyNumber: this.claiminfo.certificateNumber,
        note: this.evaluateinfo.note,
        claimNumber: this.claiminfo.claimNumber,
      };
      param.userName = JSON.parse(
        window.sessionStorage.getItem("phone")
      ).toString();
      API.saveEvaluate(param).then((res) => {
        if (!res.errorCode) {
          this.$message({
            message: "评价成功",
            type: "success",
          });
          this.$router.push({ name: "ServiceDetail" });
        }
      });
    },
  },
};
