//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["textValue","text","isEnd","maxlength"],
  data() {
    return {
      textanswer: this.textValue,
    };
  },
  watch: {
    textanswer(newval) {
      this.$emit("changeTextAnswer", newval);
    },
  },
  methods: {},
};
