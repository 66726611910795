//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as API from "@/api/apiService.js";
import { exportExcel } from "@/utils/Export2Excel.js";
import * as auth from "../utils/auth";

export default {
  components: {
  },
  data () {
    return {
      evaluate:{},
      detailDialogShow:false,
      logTime:"",
      evaluateTime:"",
      list: [],
      pageSize: 10,
      currentPageLog: 1,
      totalLog: 1,
      currentPageEvaluate: 1,
      totalEvaluate: 1,
      activeName: 'first',
      logData:[],
      evaluateData:[{"name":"13732348888","time":"2022.2.15 10:25:00","danhao":"415030000011","qingkuang":"已解决","manyidu":"3","gengduo":""}],
      isReturn:false
    }
  },
  mounted () {
    this.getLogData();
    this.getEvaluateData();
  },
  methods: {
      page_change(){},
      handleClick(tab, event) {
        console.log(tab, event);
      },
      handleCurrentChangeLog(val) {
        this.$set(this, "currentPageLog", val);
        this.getLogData(true)
      },
      handleCurrentChangeEvaluate(val) {
        this.$set(this, "currentPageEvaluate", val);
        this.getEvaluateData(true)
      },
      failedReturn(res){
        if((res.errorCode == 1000 || res.errorCode == 1001) && !this.isReturn){
          this.isReturn = true
          this.$message.error('Token失效返回登录页');
          this.logoff()
        }
      },
      getLogData(key){
        var that = this
        var params=new FormData();
        if(!key){
          this.$set(this, "currentPageLog", 1);
        }
        params.append("pageIndex",this.currentPageLog);
        params.append("pagesize",this.pageSize);
        if(this.logTime){
          var start = this.formatTimer(this.logTime[0])
          var end = this.formatTimer(this.logTime[1])
          params.append("beginTime",start);
          params.append("endTime",end);
        }
        
        API.getLogList(params).then((res) => {
          console.log(!res.errorCode)
          if (!res.errorCode) {
            that.totalLog = res.total;
            // that.logData = res.data
            
            var list = res.data;
            for(var i = 0; i < list.length; i++){
              list[i].createTime = this.formatTimer(list[i].createTime)
              //type 1:"已解决" 2:"未解决"
              list[i].type = this.logStatus(list[i].type)
            }
            that.logData = list;
          }else{
            that.failedReturn(res)
          }
        }).catch(res=>{
          console.log(res)     
        });
      },
      getEvaluateData(key){
        var that = this
        var params=new FormData();
        if(!key){
          this.$set(this, "currentPageEvaluate", 1);
        }
        params.append("pagesize",this.pageSize);
        params.append("pageIndex",this.currentPageEvaluate);
        if(this.evaluateTime){
          var start = this.formatTimer(this.evaluateTime[0])
          var end = this.formatTimer(this.evaluateTime[1])
          params.append("beginTime",start);
          params.append("endTime",end);
        }
        API.getEvaluateList(params).then((res) => {
          if (!res.errorCode) {
            that.totalEvaluate = res.total;
            // that.evaluateData = res.data
            var list = res.data;
            for(var i = 0; i < list.length; i++){
              list[i].score = Number(list[i].score)
              list[i].createTime = this.formatTimer(list[i].createTime)
              //status 0:"开始理赔" 1:"申请理赔" 2:"查看理赔申请" 3:"评价理赔" 4:"联系客服"
              list[i].status = this.evaluateStatus(list[i].status)
            }
            that.evaluateData = list;
            console.log(that.evaluateData)
          }else{
            that.failedReturn(res)
          }
        }).catch(res=>{
          console.log(res)
        });
      },
      dialogShow(val){
        console.log(val)
        this.$set(this, "evaluate", val);
        this.detailDialogShow = true
      },
      dialogDissmiss(){
        this.detailDialogShow = false
      },
      formatTimer: function (value, key) {
        let date = new Date(value);
        let y = date.getFullYear();
        let MM = date.getMonth() + 1;
        MM = MM < 10 ? "0" + MM : MM;
        let d = date.getDate();
        d = d < 10 ? "0" + d : d;
        let h = date.getHours();
        h = h < 10 ? "0" + h : h;
        let m = date.getMinutes();
        m = m < 10 ? "0" + m : m;
        let s = date.getSeconds();
        s = s < 10 ? "0" + s : s;
        if(!key){
          return y + "-" + MM + "-" + d + " " + h + ":" + m + ":" + s;
        }else{
          return y + "-" + MM + "-" + d;
        }
      },
      evaluateStatus(val){
        if(val == "1"){
          return "已解决";
        }else if(val == "2"){
          return "未解决";
        }else{
          return "";
        }
      },
      logStatus(val){
        if(val == "0"){
          return "开始理赔";
        }else if(val == "1"){
          return "申请理赔";
        }else if(val == "2"){
          return "查看理赔申请";
        }else if(val == "3"){
          return "评价理赔";
        }else if(val == "4"){
          return "联系客服";
        }else{
          return "";
        }
      },
      logoff(){
        auth.removeToken();
        this.$router.push({ name: "Login" });
      },
      exportLogExcel(){
        var that = this
        var params=new FormData();
        params.append("pageIndex",1);
        params.append("pagesize",99999999);
        if(this.logTime){
          var start = this.formatTimer(this.logTime[0])
          var end = this.formatTimer(this.logTime[1])
          params.append("beginTime",start);
          params.append("endTime",end);
        }
        API.getLogList(params).then((res) => {
          if (!res.errorCode) {
            that.totalLog = res.total;
            // that.logData = res.data
            var list = res.data;
            if (list === undefined || list.length === 0) {
              this.$message.error('没有可导出的数据！');
              return;
            }
            for(var i = 0; i < list.length; i++){
              list[i].createTime = this.formatTimer(list[i].createTime)
              //type 1:"已解决" 2:"未解决"
              list[i].type = this.logStatus(list[i].type)
            }
            var columns = [];
            columns.push({ title: "客户", key: "userName" });
            columns.push({ title: "操作时间", key: "createTime" });
            columns.push({ title: "操作内容", key: "type" });
            columns.push({ title: "服务合同单号", key: "policyNumber" });
            columns.push({ title: "服务单号", key: "claimNumber" });

            var time = "";
            var times = "";
            if(this.logTime){
              var start = this.formatTimer(this.logTime[0],"1")
              var end = this.formatTimer(this.logTime[1],"1")
              time = start + " 至 " + end + " "
              times = "(" + start + "---" + end + ")"
            }
            exportExcel(
              `${time}日志报表`,
              columns,
              list,
              `日志报表${times}`
            );
            // return list;
          }else{
            that.failedReturn(res)
          }
        }).catch(res=>{
          console.log(res)
        });
      },
      exportEvaluateExcel(){
        var that = this
        var params=new FormData();
        params.append("pagesize",99999999);
        params.append("pageIndex",1);
        if(this.evaluateTime){
          var start = this.formatTimer(this.evaluateTime[0])
          var end = this.formatTimer(this.evaluateTime[1])
          params.append("beginTime",start);
          params.append("endTime",end);
        }
        API.getEvaluateList(params).then((res) => {
          if (!res.errorCode) {
            that.totalEvaluate = res.total;
            // that.evaluateData = res.data
            var list = res.data;
            if (list === undefined || list.length === 0) {
              this.$message.error('没有可导出的数据！');
              return;
            }
            for(var i = 0; i < list.length; i++){
              list[i].score = Number(list[i].score)
              list[i].createTime = this.formatTimer(list[i].createTime)
              //status 0:"开始理赔" 1:"申请理赔" 2:"查看理赔申请" 3:"评价理赔" 4:"联系客服"
              list[i].status = this.evaluateStatus(list[i].status)
            }
            var columns = [];
            columns.push({ title: "客户", key: "userName" });
            columns.push({ title: "服务申请时间", key: "createTime" });
            columns.push({ title: "服务单号", key: "claimNumber" });
            columns.push({ title: "处理情况", key: "status" });
            columns.push({ title: "满意度", key: "score" });
            columns.push({ title: "不满意原因或者建议", key: "note" });

            var time = "";
            var times = "";
            if(this.evaluateTime){
              var start = this.formatTimer(this.evaluateTime[0],"1")
              var end = this.formatTimer(this.evaluateTime[1],"1")
              time = start + " 至 " + end
              times = "(" + start + "---" + end + ")"
            }
            exportExcel(
              `${time}评价报表`,
              columns,
              list,
              `评价报表${times}`
            );
          } else {
            that.failedReturn(res)
          }
        }).catch(res=>{
          console.log(res)
        });
      }
  }
}
