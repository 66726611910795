//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as API from "@/api/apiService.js";
import { kefu } from "@/utils/udesk.js";
export default {
  data() {
    return {
      claimdata: null,
      phone: "",
      firstName: "",
      lastName: "",
      EmailAddress: "",
      dealerCode: "",
      itemInfo: [],
      warrantyPlan: "",
      tit1eNumber: "",
      from: null,
      udeskParam:null
    };
  },
  activated() {
    //对接客服
    this.udeskParam = JSON.parse(window.sessionStorage.getItem("udeskParam"));
    kefu(this.udeskParam);
    if (
      this.from == "Landing" ||
      this.from == "ServiceType" ||
      this.from == null
    ) {
      Object.assign(this.$data, this.$options.data());
      this.init();
    }else{
      this.init()
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //  这里的vm指的就是vue实例，可以用来当做this使用
      console.log(to);
      console.log(from.name);
      vm.from = from.name;
    });
  },
  methods: {
    init() {
      try {
        this.udeskParam = JSON.parse(window.sessionStorage.getItem("udeskParam"));
        kefu(this.udeskParam);
        this.claimdata = JSON.parse(
          window.sessionStorage.getItem("serviceData")
        );
        this.dealerCode = JSON.parse(
          window.sessionStorage.getItem("dealerCode")
        );
        this.warrantyPlan = JSON.parse(
          window.sessionStorage.getItem("warrantyPlan")
        );
        if (this.claimdata.itemInfo.length > 0) {
          this.itemInfo = this.claimdata.itemInfo[0];
        }
        if (this.claimdata.customers.length > 0) {
          let customer = this.claimdata.customers[0];
          this.firstName = this.isNaN(customer.firstName);
          this.lastName = this.isNaN(customer.lastName);
          this.EmailAddress = this.isNaN(customer.emailEmailAddress);
          this.getPhone(customer);
        }
      } catch (error) {
        console.log("error", error);
      }
      this.getTitle();
    },
    next() {
      if (this.lastName === "") {
        this.$message.warning("请输入客户姓");
        return;
      }
      if (this.firstName === "") {
        this.$message.warning("请输入客户名");
        return;
      }
      this.claimdata.customers[0].lastName = this.lastName;
      this.claimdata.customers[0].firstName = this.firstName;
      window.sessionStorage.setItem(
        "serviceData",
        JSON.stringify(this.claimdata)
      );
      var param = {
        CertificateNumber: this.claimdata.certificateNumber,
        FirstName: this.firstName,
        LastName: this.lastName,
        EmailAddress: this.EmailAddress,
        PhoneNumber: this.phone,
        DealerCode: this.dealerCode,
      };
      API.beginClaim(param).then((res) => {
        if (!res.errorCode) {
          window.sessionStorage.setItem("beginClaim", JSON.stringify(res));
          if (this.warrantyPlan === "Existing subscriber plan") {
            this.$router.push({ name: "UploadFile" });
          } else if (
            this.warrantyPlan === "Platinum" ||
            this.warrantyPlan === "Silver" ||
            this.warrantyPlan === "Gold" ||
            this.warrantyPlan === ""
          ) {
            this.$router.push({ name: "Questions" });
          } else {
            this.$router.push({ name: "Questions" });
          }
        }
      });
    },
    getPhone(customer) {
      let phoneInfo = customer.phone;
      if (phoneInfo.length > 0) {
        for (let index = 0; index < phoneInfo.length; index++) {
          const element = phoneInfo[index];
          if (element.type === 1 || element.type === "HomePhone") {
            this.phone = element.phoneNumber;
            return this.phone;
          }
        }
      }
      if (this.phone == "") {
        this.$message.error("保单信息不全，缺失客户手机号！");
      }
      return this.phone;
    },
    getTitle() {
      if (
        //存量机申请理赔需要四步，铂金计划，金计划和银计划申请理赔不需要上传申请材料，只需要三步
        this.warrantyPlan === "Existing subscriber plan"
      ) {
        this.tit1eNumber = "1/4";
      } else if (
        this.warrantyPlan === "Platinum" ||
        this.warrantyPlan === "Silver" ||
        this.warrantyPlan === "Gold" ||
        this.warrantyPlan === ""
      ) {
        this.tit1eNumber = "1/3";
      } else {
        this.tit1eNumber = "1/3";
      }
    },
    isNaN(text) {
      if (!text && text === undefined && text === null) {
        return "";
      } else {
        return text;
      }
    },
    goHelp() {
      this.$router.push({ name: "CommonFaultHelp" });
    },
    contactKefu() {
      var param = {
        userName: this.phone,
        type: 4,
        policyNumber: this.claimdata.certificateNumber,
        claimNumber: "",
      };
      API.addLog(param).then((res) => {
        console.log(res)
      });
    },
  },
};
