//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// @ is an alias to /src
import * as API from "@/api/apiService.js";
import * as auth from "../utils/auth";
export default {
  data() {
    return {
      password: "",
      userName: "",
    };
  },
  mounted(){
    var that = this
    document.onkeydown = function(e){
      var key = null
      if(window.event == undefined){
        key = e.keyCode
      }else{
        key = window.event.keyCode
      }
      if(key == 13){
        if(that.userName == "" || that.password == ""){
          return false;
        }else{
          that.login()
        }
      }
    }
  },
  methods: {
    login() {
      API.userLogin({ userName: this.userName, password: this.password }).then(
        (res) => {
          if (!res.errorCode) {
            auth.setToken(res.access_token);
            this.$router.push({ name: "DiaryList" });
          }
            // this.$message.error(res.message);
        }
      ).catch(res=>{
        console.log(res)
      });
    },
  },
};
