import request from "@/utils/request";
//h5 login
export function login(params) {
  return request({
    method: "post",
    url: "/api/policy/login",
    loading: true,
    data: params,
  });
}

//理赔详情
export function claimDetail(id,params) {
  return request({
    method: "get",
    url: "/api/claim/" + id,
    loading: true,
    data: params,
  });
}

//评价详情
export function evaluateinfo(params) {
  return request({
    method: "post",
    url: "/api/evaluate/detail",
    loading: true,
    data: params,
  });
}

//新增评价
export function saveEvaluate(params) {
  return request({
    method: "post",
    url: "/api/evaluate/add",
    loading: true,
    data: params,
  });
}

//开始理赔
export function beginClaim(params) {
  return request({
    method: "post",
    url: "/api/claim/beginClaim",
    loading: true,
    data: params,
  });
}

//web login
export function userLogin(params) {
  return request({
    method: "post",
    url: "/api/Users/login",
    loading: true,
    data: params,
  });
}
//
export function getLogList(params) {
  return request({
    method: "post",
    url: "/api/log/list",
    loading: true,
    data: params,
  });
}

export function addLog(params) {
  return request({
    method: "post",
    url: "/api/log/add",
    loading: true,
    data: params,
  });
}

//
export function getEvaluateList(params) {
  return request({
    method: "post",
    url: "/api/evaluate/list",
    loading: true,
    data: params,
  });
}

//发送验证码
export function sendMessage(params) {
  return request({
    method: "post",
    url: "/api/SMS/send",
    loading: true,
    data: params,
  });
}

//验证验证码
export function checkMessage(params) {
  return request({
    method: "post",
    url: "/api/SMS/check",
    loading: true,
    data: params,
  });
}

//获取问题列表
export function submitdevice(params) {
  return request({
    method: "post",
    url: "/api/claim/submitdevice",
    loading: true,
    data: params,
  });
}

//提交
export function submit(params) {
  return request({
    method: "post",
    url: "/api/claim/submit",
    loading: true,
    data: params,
  });
}
