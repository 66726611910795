//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["inputText", "text", "type", "isEnd"],
  data() {
    return {
      inputanswer: this.inputText,
    };
  },
  watch: {
    inputanswer(newval) {
      this.$emit("changeInputAnswer", newval);
    },
  },
  methods: {},
};
