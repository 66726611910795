export function kefu(udeskStr) {
  (function (a, h, c, b, f, g) {
    a["UdeskApiObject"] = f;
    a[f] =
      a[f] ||
      function () {
        (a[f].d = a[f].d || []).push(arguments);
      };
    g = h.createElement(c);
    g.async = 1;
    g.charset = "utf-8";
    g.src = b;
    c = h.getElementsByTagName(c)[0];
    c.parentNode.insertBefore(g, c);
  })(
    window,
    document,
    "script",
    "https://assets-cli.s4.udesk.cn/im_client/js/udeskApi.js",
    "ud"
  );

  // eslint-disable-next-line no-undef
  ud({
    // code: "16a16e73",
    // link: "https://tpuat00001.s4.udesk.cn/im_client/web_plugin_id=32158",
    code: "1cb2bh24",
    link: "https://assurant.s4.udesk.cn/im_client/?web_plugin_id=32872",
    mode: "inner",
    targetSelector: ".contact",
    selector: ".contact",
    customer: {
      nonce: udeskStr.nonce,
      signature: udeskStr.signstr,
      timestamp: udeskStr.Timestamp,
      web_token: udeskStr.Webtoken,
      c_name: udeskStr.name,
      c_phone: udeskStr.phone,
      c_cf_保单号: udeskStr.certificateNumber,
      c_cf_理赔单号: udeskStr.claimId,
      c_cf_咨询内容: udeskStr.type,
    },
  });
}
