//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as API from "@/api/apiService.js";
import * as auth from "../utils/auth";
import Sha1 from "js-sha1";
export default {
  data() {
    return {
      showError: false,
      customer: null,
      certificateNumber: "",
      type: "",
      claimId: " ",
      phone: "",
    };
  },
  mounted() {
    window.sessionStorage.removeItem("serviceData");
    this.vertifyCode();
  },
  methods: {
    vertifyCode() {
      let code = "";
      let params = "";
      try {
        code = decodeURIComponent(this.$route.query.c);
        params = JSON.parse(code);
      } catch (e) {
        console.log("failed to parse Json, param = " + this.$route.query.c);
        // console.log("decodeUrlResult = " + code)
        console.log(e);
        this.showError = true;
        return;
      }

      if (params) {
        if (!this.vertifyParams(params)) {
          return;
        }
        API.login(params)
          .then((res) => {
            if (!res.errorCode) {
              this.data = res;
              auth.setToken(res.access_token);
              window.sessionStorage.setItem(
                "serviceData",
                JSON.stringify(this.data)
              );

              if (params.contractRoot.action === "claimApply") {
                this.type = "理赔申请";
                if (res.warrantyPlan === "Platinum") {
                  //如果是铂金计划，跳转到铂金选择页面
                  this.$router.replace({ name: "ServiceType" });
                } else {
                  //如果不是铂金计划直接跳转设备保障开始申请理赔页面
                  this.$router.replace({ name: "ComfirmInfo" });
                }
              } else if (params.contractRoot.action === "claimDetail") {
                this.type = "理赔查询";
                this.claimId = params.contractRoot.claimId;
                window.sessionStorage.setItem(
                  "claimId",
                  JSON.stringify(params.contractRoot.claimId)
                );
                this.$router.replace({ name: "ServiceDetail" });
              }
              this.certificateNumber = res.certificateNumber;
              let customer = res.customers[0];
              this.customer = customer;
              let phone = this.getPhone(customer);
              this.phone = phone;
              let warrantyPlan = res.warrantyPlan;
              window.sessionStorage.setItem(
                "warrantyPlan",
                JSON.stringify(warrantyPlan)
              );
              window.sessionStorage.setItem("phone", JSON.stringify(phone));
              window.sessionStorage.setItem(
                "dealerCode",
                JSON.stringify(params.contractRoot.dealerCode)
              );
              const Timestamp = new Date().getTime();
              const nonce = Math.round(Math.random() * 10000);
              const Webtoken = this.phone;
              // const key = "f3973e332dd2cb30ac1d5f48e78f16d4";
              const key = "510aa5fee9958c0bd5652b87dcd2d12b"
              let signstr =
                "nonce=" +
                nonce +
                "&timestamp=" +
                Timestamp +
                "&web_token=" +
                Webtoken +
                "&" +
                key;
              signstr = Sha1(signstr);
              signstr = signstr.toUpperCase();
              // this.Udesk();
              var udeskParam = {
                name: this.customer.lastName + this.customer.firstName,
                phone: this.phone,
                certificateNumber: this.certificateNumber,
                claimId: this.claimId,
                type: this.type,
                signstr:signstr,
                nonce:nonce,
                Timestamp:Timestamp,
                Webtoken:Webtoken
              };
              window.sessionStorage.setItem(
                "udeskParam",
                JSON.stringify(udeskParam)
              );
            } else {
              this.showError = true;
            }
          })
          .catch((res) => {
            console.log("error", res);
            this.showError = true;
          });
      } else {
        this.showError = true;
      }
    },
    // Udesk() {
    //   (function (a, h, c, b, f, g) {
    //     a.UdeskApiObject = f;
    //     a[f] =
    //       a[f] ||
    //       function () {
    //         (a[f].d = a[f].d || []).push(arguments);
    //       };
    //     g = h.createElement(c);
    //     g.async = 1;
    //     g.charset = "utf-8";
    //     g.src = b;
    //     c = h.getElementsByTagName(c)[0];
    //     c.parentNode.insertBefore(g, c);
    //   })(
    //     window,
    //     document,
    //     "script",
    //     "https://assets-cli.s4.udesk.cn/im_client/js/udeskApi.js",
    //     "ud"
    //   );
    //   const Timestamp = new Date().getTime();
    //   const nonce = Math.round(Math.random() * 10000);
    //   const Webtoken = this.phone
    //   const key = "f3973e332dd2cb30ac1d5f48e78f16d4";
    //   let signStr ="nonce="+nonce +"&timestamp="+Timestamp +"&web_token="+Webtoken +"&"+key;
    //   signStr = Sha1(signStr);
    //   signStr = signStr.toUpperCase();
    //   // eslint-disable-next-line no-undef
    //   ud({
    //     code: "16a16e73",
    //     link: "https://tpuat00001.s4.udesk.cn/im_client/?web_plugin_id=32158",
    //     mode: "inner",
    //     targetSelector: ".contact",
    //     selector: ".contact",
    //     customer: {
    //       nonce: nonce,
    //       signature: signStr,
    //       timestamp: Timestamp,
    //       web_token: Webtoken,
    //       c_name: this.customer.lastName + this.customer.firstName,
    //       c_phone: this.phone,
    //       c_cf_保单号: this.certificateNumber,
    //       c_cf_理赔单号: this.claimId,
    //       c_cf_咨询内容: this.type,
    //     },
    //   });
    // },
    getPhone(customer) {
      let phoneInfo = customer.phone;
      var phone = "";
      if (phoneInfo.length > 0) {
        for (let index = 0; index < phoneInfo.length; index++) {
          const element = phoneInfo[index];
          if (element.type === 1 || element.type === "HomePhone") {
            phone = element.phoneNumber;
            return phone;
          }
        }
      }
      return phone;
    },
    vertifyParams(param) {
      var result = false;
      if (param.contractRoot) {
        let contractRoot = param.contractRoot;
        if (
          contractRoot.recoOrderId &&
          contractRoot.reqTime &&
          contractRoot.sign &&
          contractRoot.action &&
          contractRoot.dealerCode &&
          contractRoot.version
        ) {
          if (
            (contractRoot.action === "claimDetail" && contractRoot.claimId) ||
            contractRoot.action === "claimApply"
          ) {
            result = true;
          }
        }
      }
      if (result === false) {
        console.log("invalid param");
        this.showError = true;
      }
      return result;
    },
  },
};
