//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as API from "@/api/apiService.js";
import { kefu } from "@/utils/udesk.js";
export default {
  data() {
    return {
      selectedOptions: [],
      firstName: "", //客户名
      lastName: "", //客户姓
      addressState: "", //省
      addressCity: "", //市
      addressDetail: "", //详细地址
      addressDetail2: "", //详细地址2
      addressDetail3: "", //详细地址3
      code: "", //验证码
      phoneNumber: "", //客户手机号
      show: true, //是否开始倒计时
      count: "",
      timer: null,
      warrantyPlan: "", //客户服务计划
      tit1eNumber: "", //步骤
      questionData: [],
      beginClaim: null,
      dealerCode: "",
      documentData: null,
      proviceList: [
        { label: "上海市", value: "上海" },
        { label: "云南省", value: "云南" },
        { label: "内蒙古自治区", value: "内蒙古" },
        { label: "北京市", value: "北京" },
        { label: "台湾省", value: "台湾" },
        { label: "吉林省", value: "吉林" },
        { label: "四川省", value: "四川" },
        { label: "天津市", value: "天津" },
        { label: "宁夏回族自治区", value: "宁夏" },
        { label: "安徽省", value: "安徽" },
        { label: "山东省", value: "山东" },
        { label: "山西省", value: "山西" },
        { label: "广东省", value: "广东" },
        { label: "广西壮族自治区", value: "广西" },
        { label: "新疆维吾尔自治区", value: "新疆" },
        { label: "江苏省", value: "江苏" },
        { label: "江西省", value: "江西" },
        { label: "河北省", value: "河北" },
        { label: "河南省", value: "河南" },
        { label: "浙江省", value: "浙江" },
        { label: "海南省", value: "海南" },
        { label: "湖北省", value: "湖北" },
        { label: "湖南省", value: "湖南" },
        { label: "澳门特别行政区", value: "澳门" },
        { label: "甘肃省", value: "甘肃" },
        { label: "福建省", value: "福建" },
        { label: "西藏自治区", value: "西藏" },
        { label: "贵州省", value: "贵州" },
        { label: "辽宁省", value: "辽宁" },
        { label: "重庆市", value: "重庆" },
        { label: "陕西省", value: "陕西" },
        { label: "青海省", value: "青海" },
        { label: "香港特别行政区", value: "香港" },
        { label: "黑龙江省", value: "黑龙江" },
      ],
      from: null,
      udeskParam: null,
    };
  },
  activated() {
    //对接客服
    this.udeskParam = JSON.parse(window.sessionStorage.getItem("udeskParam"));
    kefu(this.udeskParam);
    if (this.from == "Questions" || this.from == null) {
      Object.assign(this.$data, this.$options.data());
      this.init();
    }
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //  这里的vm指的就是vue实例，可以用来当做this使用
      console.log(to);
      console.log(from.name);
      vm.from = from.name;
    });
  },
  methods: {
    init() {
      this.udeskParam = JSON.parse(window.sessionStorage.getItem("udeskParam"));
      kefu(this.udeskParam);
      if (JSON.parse(window.sessionStorage.getItem("phone")) == "") {
        this.$message.error("保单信息不全，缺失客户手机号！");
      } else {
        this.phoneNumber = JSON.parse(window.sessionStorage.getItem("phone"));
      }

      this.warrantyPlan = JSON.parse(
        window.sessionStorage.getItem("warrantyPlan")
      );
      this.questionData = JSON.parse(
        window.sessionStorage.getItem("questionData")
      );
      this.beginClaim = JSON.parse(window.sessionStorage.getItem("beginClaim"));
      this.dealerCode = JSON.parse(window.sessionStorage.getItem("dealerCode"));
      let serviceData = JSON.parse(
        window.sessionStorage.getItem("serviceData")
      );
      this.addressState = serviceData.customers[0].addressState;
      this.addressCity = serviceData.customers[0].addressCity;
      this.addressDetail = serviceData.customers[0].addressAddress1;
      this.addressDetail2 = serviceData.customers[0].addressAddress2;
      this.addressDetail3 = serviceData.customers[0].addressAddress3;
      this.firstName = serviceData.customers[0].firstName;
      this.lastName = serviceData.customers[0].lastName;
      this.getTitle();
      this.oparateState();
    },
    goHelp() {
      this.$router.push({ name: "CommonFaultHelp" });
    },
    oparateState() {
      this.proviceList.forEach((element) => {
        if (this.addressState == element.label) {
          this.addressState = element.value;
        }
      });
    },
    checkMessage() {
      if (this.addressState == "") {
        this.$message.warning("请选择省份");
        return;
      }
      if (this.addressCity == "") {
        this.$message.warning("请填写城市");
        return;
      }
      if (this.addressDetail == "") {
        this.$message.warning("请填写详细地址1");
        return;
      }
      if (this.code === "") {
        this.$message.warning("请输入短信验证码！");
        return;
      }
      this.submit();
    },
    submit() {
      var customerData = {
        FirstName: this.firstName,
        LastName: this.lastName,
        AddressAddress1: this.addressDetail,
        AddressAddress2: this.addressDetail2,
        AddressAddress3: this.addressDetail3,
        AddressCity: this.addressCity,
        AddressState: this.addressState,
      };
      let certificateNumber = this.beginClaim.certificateNumber;
      var submitparams = {
        captcha: this.code,
        userName: this.phoneNumber,
        dealerCode: this.dealerCode,
        certificateNumber: certificateNumber,
        questionData: this.questionData,
        customerData: customerData,
      };
      if (
        //存量机申请理赔需要上传申请材料
        this.warrantyPlan === "Existing subscriber plan"
      ) {
        submitparams.documentData = this.documentData;
      }
      API.submit(submitparams)
        .then((res) => {
          if (!res.errorCode) {
            if (res.code != 0) {
              if (
                res.docErrMsg &&
                res.docErrMsg.indexOf("DUPLICATE_FILE_NAME") != -1
              ) {
                this.$message.warning("上传文件名称重复！");
              } else if (
                res.docErrMsg &&
                res.docErrMsg.indexOf("FILE_TYPE_NOT_VALID") != -1
              ) {
                this.$message.warning("上传文件格式不支持！");
              } else {
                this.$message.warning(res.message);
              }
            }
            this.$router.push({
              name: "Success",
              query: { status: "success", claimNo: res.data.claimNumber },
            });
          }
        })
        .catch((res) => {
          if (res) {
            if (res.response.data.errorCode == 920) {
              return;
            } else {
              this.$router.push({
                name: "Success",
                query: {
                  status: "fail",
                  errorMessage: res.response.data.message,
                },
              });
            }
          }
        });
    },
    getCode() {
      if (this.phoneNumber === "") {
        this.$message.warning("保单信息不全，缺失手机号，请联系客服！");
        return;
      }
      API.sendMessage({ PhoneNumber: this.phoneNumber }).then((res) => {
        if (res.message === "OK") {
          let str = String(this.phoneNumber);
          var reg = /^(\d{3})\d{4}(\d{4})$/;
          str = str.replace(reg, "$1****$2");
          this.$message.success(
            "已向手机号为" + str + "发送短信验证码，请查收！"
          );
          const TIME_COUNT = 240;
          if (!this.timer) {
            this.count = TIME_COUNT;
            this.show = false;
            this.timer = setInterval(() => {
              if (this.count > 0 && this.count <= TIME_COUNT) {
                this.count--;
              } else {
                this.show = true;
                clearInterval(this.timer);
                this.timer = null;
              }
            }, 1000);
          }
        }
      });
    },
    getTitle() {
      if (
        //铂金计划和存量机申请理赔需要四步，金计划和银计划申请理赔不需要上传申请材料，只需要三步
        this.warrantyPlan === "Existing subscriber plan"
      ) {
        this.tit1eNumber = "4/4";
        this.documentData = JSON.parse(
          window.sessionStorage.getItem("documentData")
        );
      } else if (
        this.warrantyPlan === "Platinum" ||
        this.warrantyPlan === "Silver" ||
        this.warrantyPlan === "Gold" ||
        this.warrantyPlan === ""
      ) {
        this.tit1eNumber = "3/3";
      } else {
        this.tit1eNumber = "3/3";
      }
    },
    contactKefu() {
      var param = {
        userName: this.phoneNumber,
        type: 4,
        policyNumber: this.udeskParam.certificateNumber,
        claimNumber: "",
      };
      API.addLog(param).then((res) => {
        console.log(res);
      });
    },
  },
};
