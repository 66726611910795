//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as API from "@/api/apiService.js";
import { kefu } from "@/utils/udesk.js";
export default {
  data() {
    return {
      claimId: "",
      claimDetailData: "",
      serviceStatus: "",
      extendedStatus: "",
      extendedStatusDate: "",
      reportedDate: "",
      callerName: "",
      phone: "",
      udeskParam:null
    };
  },
  mounted() {
    //对接客服
    this.udeskParam = JSON.parse(window.sessionStorage.getItem("udeskParam"));
    kefu(this.udeskParam);
    this.claimId = JSON.parse(window.sessionStorage.getItem("claimId"));
    if (JSON.parse(window.sessionStorage.getItem("phone")) == "") {
      this.$message.error("保单信息不全，缺失客户手机号！");
    } else {
      this.phone = JSON.parse(window.sessionStorage.getItem("phone"));
    }
    this.getClaimDetail();
  },
  methods: {
    getClaimDetail() {
      var formData = new FormData();
      formData.append("UserName", this.phone);
      formData.append("WriteLog", 1);
      API.claimDetail(this.claimId, formData)
        .then((res) => {
          if (!res.errorCode) {
            this.claimDetailData = res;
            this.serviceStatus = this.getStatusText(res.claimStatus);
            // format reprotedDate
            if (res.reportedDate != null && res.reportedDate != "" && res.reportedDate != "undefined"){
              this.reportedDate = res.reportedDate;
              if (this.reportedDate.indexOf(" ") != -1){
                this.reportedDate = this.reportedDate.split(" ")[0];
              }
            }
            // handle ExtendedStatus
            if (res.claimExtendedStatusDescriptionCN != null && res.claimExtendedStatusDescriptionCN != "" && res.claimExtendedStatusDescriptionCN != "undefined"){
              this.extendedStatus = res.claimExtendedStatusDescriptionCN;
              this.extendedStatusDate = res.claimExtendedStatusDate;
            } else {
              this.extendedStatus = this.serviceStatus;
              this.extendedStatusDate = this.reportedDate;
            }
            // handler callerName
            if (res.callerName != null && res.callerName != "" && res.callerName != "undefined"){
              this.callerName = res.callerName;
              if (this.callerName.indexOf(" ") != -1){
                var firstName = this.callerName.split(" ")[0];
                var lastName = this.callerName.split(" ")[1];
                this.callerName = lastName + firstName;
              }
            } 
          }
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getStatusText(code) {
      switch (code) {
        case "A":
          return "服务进行中";
        case "P":
          return "审核中";
        case "D":
          return "申请失败";
        case "C":
          return "服务完成";
        default:
          return "";
      }
    },
    evaluate() {
      window.sessionStorage.setItem(
        "claiminfo",
        JSON.stringify(this.claimDetailData)
      );
      this.$router.push({ name: "Evaluate" });
    },
     contactKefu() {
      var param = {
        userName: this.phone,
        type: 4,
        policyNumber: this.udeskParam.certificateNumber,
        claimNumber: this.claimId,
      };
      API.addLog(param).then((res) => {
        console.log(res)
      });
    },
  },
};
