//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as API from "@/api/apiService.js";
import * as imageConversion from "image-conversion";
import "@vant/touch-emulator";
import { kefu } from "@/utils/udesk.js";

export default {
  data() {
    return {
      fileList: [],
      phone: "",
      from: null,
      udeskParam: null,
    };
  },
  activated() {
    //对接客服
    this.udeskParam = JSON.parse(window.sessionStorage.getItem("udeskParam"));
    kefu(this.udeskParam);
    if (this.from == "ComfirmInfo" || this.from == null) {
      Object.assign(this.$data, this.$options.data());
      this.udeskParam = JSON.parse(window.sessionStorage.getItem("udeskParam"));
      kefu(this.udeskParam);
    }
    if (JSON.parse(window.sessionStorage.getItem("phone")) == "") {
      this.$message.error("保单信息不全，缺失客户手机号！");
    } else {
      this.phone = JSON.parse(window.sessionStorage.getItem("phone"));
    }
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //  这里的vm指的就是vue实例，可以用来当做this使用
      console.log(to);
      console.log(from.name);
      vm.from = from.name;
    });
  },
  methods: {
    // 获取图片转base64
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        const reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    goHelp() {
      this.$router.push({ name: "OrderSampleHelp" });
    },
    next() {
      if (this.fileList.length < 1) {
        this.$message({
          showClose: true,
          message: "请上传申请材料！",
          type: "warning",
        });
        return;
      }
      for (let index = 0; index < this.fileList.length; index++) {
        const image = this.fileList[index];
        imageConversion
          .compressAccurately(image.file, 500)
          .then(async (res) => {
            // Blob转file类型
            const resFile = new window.File([res], image.file.name, {
              type: image.file.type,
            });
            console.log(resFile);
            var imgBase64 = await this.getBase64(resFile);
            var base64img = imgBase64.substring(imgBase64.indexOf(',')+1)
            //console.log("imgBase64: ", imgBase64);
            //console.log("base64img: ", base64img);
            // this.uploadFileList.push(base64img);
            // this.$message.success(resFile.name)
            var documentData = {
              FileName: resFile.name,
              UserName: this.phone,
              ImageData: base64img,
            };
            window.sessionStorage.setItem(
              "documentData",
              JSON.stringify(documentData)
            );
          });
      }
      this.$router.push({ name: "Questions" });
    },
    contactKefu() {
      var param = {
        userName: this.phone,
        type: 4,
        policyNumber: this.udeskParam.certificateNumber,
        claimNumber: "",
      };
      API.addLog(param).then((res) => {
        console.log(res);
      });
    },
  },
};
