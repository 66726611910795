//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: ["dateValue", "text", "isEnd"],
  data() {
    return {
      dateanswer: this.dateValue,
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  watch: {
    dateanswer(newval) {
      this.$emit("changeDateAnswer", newval);
    },
  },
};
