//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { kefu } from "@/utils/udesk.js";
import * as API from "@/api/apiService.js";
export default {
  data() {
    return {
      radio: 3,
      udeskParam:null
    };
  },
  mounted() {
    //对接客服
    this.udeskParam = JSON.parse(window.sessionStorage.getItem("udeskParam"));
    kefu(this.udeskParam);
  },
  methods: {
    next() {
      this.$router.replace({ name: "ComfirmInfo" });
    },
    goHelp() {
      this.$router.push({ name: "CommonFaultHelp" });
    },
    contactKefu() {
      var param = {
        userName: JSON.parse(window.sessionStorage.getItem("phone")),
        type: 4,
        policyNumber: this.udeskParam.certificateNumber,
        claimNumber: "",
      };
      API.addLog(param).then((res) => {
        console.log(res);
      });
    },
  },
};
