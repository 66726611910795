//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as API from "@/api/apiService.js";
export default {
  data() {
    return {
      name: "",
      serphone: "",
      port: "",
      state: "",
      address: "",
      showSuccess: true,
      warrantyPlan: "", //是否为存量机
      claimNo: "",
      phone: "",
      errorMessage: "",
    };
  },
  mounted() {
    let status = this.$route.query.status;
    this.claimNo = this.$route.query.claimNo;
    this.errorMessage = this.$route.query.errorMessage;
    if (JSON.parse(window.sessionStorage.getItem("phone")) == "") {
      this.$message.error("保单信息不全，缺失客户手机号！");
    } else {
      this.phone = JSON.parse(window.sessionStorage.getItem("phone"));
    }
    this.warrantyPlan = JSON.parse(
      window.sessionStorage.getItem("warrantyPlan")
    );
    if (status == "fail") {
      this.showSuccess = false;
    } else if (status == "success") {
      this.showSuccess = true;
      if (this.warrantyPlan !== "Existing subscriber plan") {
        var formData = new FormData();
        formData.append("UserName", this.phone);
        formData.append("WriteLog", 0);
        API.claimDetail(this.claimNo, formData)
          .then((res) => {
            if (!res.errorCode) {
              this.name = res.serviceCenter.description;
              this.serphone = res.serviceCenter.phone;
              this.port = res.serviceCenter.addressPostalCode;
              this.state =
                res.serviceCenter.addressState + res.serviceCenter.addressCity;
              this.address =
                res.serviceCenter.addressAddress1 + " " +
                res.serviceCenter.addressAddress2 + " " +
                res.serviceCenter.addressAddress3;
            }
          })
          .catch((res) => {
            console.log(res);
          });
      }
    }
  },
  methods: {
    back() {
      this.$router.push({ path: "/comfirmInfo" });
    },
  },
};
