import Vue from "vue";
import VueRouter from "vue-router";
import ComfirmInfo from "../views/ComfirmInfo.vue";
import UploadFile from "../views/UploadFile.vue";
import Questions from "../views/Questions.vue";
import AddressInfo from "../views/AddressInfo.vue";
import UpdateInfo from "../views/UpdateInfo.vue";
import ContractDetail from "../views/ContractDetail.vue";

import Success from "../views/Success.vue";
import Evaluate from "../views/Evaluate.vue";
import ServiceDetail from "../views/ServiceDetail.vue";
import Login from "../views/Login.vue";
import DiaryList from "../views/DiaryList.vue";
import Landing from "../views/Landing.vue";
import ServiceType from "../views/ServiceType.vue";
import CommonFaultHelp from "../views/CommonFaultHelp.vue";
import OrderSampleHelp from "../views/OrderSampleHelp.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Landing",
    component: Landing,
  },
  {
    path: "/comfirmInfo",
    name: "ComfirmInfo",
    meta: { keepAlive: true },
    component: ComfirmInfo,
  },
  {
    path: "/uploadFile",
    name: "UploadFile",
    meta: { keepAlive: true },
    component: UploadFile,
  },
  {
    path: "/questions",
    name: "Questions",
    meta: { keepAlive: true },
    component: Questions,
  },
  {
    path: "/addressInfo",
    name: "AddressInfo",
    meta: { keepAlive: true },
    component: AddressInfo,
  },
  {
    path: "/updateInfo",
    name: "UpdateInfo",
    component: UpdateInfo,
  },
  {
    path: "/contractDetail",
    name: ContractDetail,
    component: ContractDetail,
  },
  {
    path: "/success",
    name: "Success",
    component: Success,
  },
  {
    path: "/evaluate",
    name: "Evaluate",
    component: Evaluate,
  },
  {
    path: "/serviceDetail",
    name: "ServiceDetail",
    component: ServiceDetail,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/diaryList",
    name: "DiaryList",
    component: DiaryList,
  },
  {
    path: "/serviceType",
    name: "ServiceType",
    component: ServiceType,
  },
  {
    path: "/orderSampleHelp",
    name: "OrderSampleHelp",
    component: OrderSampleHelp,
  },
  {
    path: "/commonFaultHelp",
    name: "CommonFaultHelp",
    component: CommonFaultHelp,
  },
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
});

export default router;
