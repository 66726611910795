var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"h5-container"},[_c('el-scrollbar',{staticStyle:{"height":"calc(100vh - 0.52rem)"}},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"header"},[_c('div',{staticStyle:{"height":"0.1px"}}),_c('div',{staticClass:"pageNum"},[_vm._v(_vm._s(_vm.tit1eNumber))]),_c('div',{staticClass:"title font-24"},[_vm._v("服务报修")])]),_c('div',{staticClass:"userinfo"},_vm._l((_vm.questionList),function(item,index){return _c('div',{key:index},[(
              item.answerType === 0 &&
              _vm.isShow(item.conditionObj, item.preConditionType, item)
            )?_c('select-template',{attrs:{"selectList":item.answer.answers,"selectValue":item.answer.answer.text,"text":item.text,"isEnd":index + 1 === _vm.questionList.length ? true : false},on:{"changeSelectAnswer":function($event){return _vm.changeSelectanswer($event, item)}}}):_vm._e(),(
              item.answerType === 1 &&
              _vm.isShow(item.conditionObj, item.preConditionType, item)
            )?_c('date-template',{attrs:{"dateValue":item.answer.answer,"text":item.text,"isEnd":index + 1 === _vm.questionList.length ? true : false},on:{"changeDateAnswer":function($event){return _vm.changeDateanswer($event, item)}}}):_vm._e(),(
              item.answerType === 2 &&
              _vm.isShow(item.conditionObj, item.preConditionType, item)
            )?_c('textarea-template',{attrs:{"textValue":item.answer.answer,"text":item.text,"maxlength":item.length,"isEnd":index + 1 === _vm.questionList.length ? true : false},on:{"changeTextAnswer":function($event){return _vm.changeTextanswer($event, item)}}}):_vm._e(),(
              item.answerType === 3 &&
              _vm.isShow(item.conditionObj, item.preConditionType, item)
            )?_c('select-template',{attrs:{"selectList":item.answer.answers,"selectValue":item.answer.answer.text,"text":item.text,"isEnd":index + 1 === _vm.questionList.length ? true : false,"conditions":item.preConditions[0]},on:{"changeSelectAnswer":function($event){return _vm.changeSelectanswer($event, item)}}}):_vm._e(),(
              item.answerType === 4 &&
              _vm.isShow(item.conditionObj, item.preConditionType, item)
            )?_c('input-template',{attrs:{"inputText":item.answer.answer,"text":item.text,"type":item.answerType,"isEnd":index + 1 === _vm.questionList.length ? true : false},on:{"changeInputAnswer":function($event){return _vm.changeInputanswer($event, item)}}}):_vm._e()],1)}),0)])]),_c('div',{staticClass:"bottomdiv"},[_c('div',{staticClass:"contact font-12",on:{"click":function($event){return _vm.contactKefu()}}},[_c('img',{staticClass:"imgkefu",attrs:{"src":require("../assets/icon_kefu_n.png")}}),_c('div',{staticClass:"labkefu"},[_vm._v("联系客服")])]),_c('el-divider',{attrs:{"direction":"vertical"}}),_c('div',{staticClass:"help font-12",on:{"click":function($event){return _vm.goHelp()}}},[_c('img',{staticClass:"imgkefu",staticStyle:{"margin-left":"0px"},attrs:{"src":require("../assets/icon_help_n.png")}}),_c('div',{staticClass:"labkefu"},[_vm._v("常见故障")])]),_c('div',{staticClass:"next font-16",on:{"click":function($event){return _vm.next()}}},[_c('div',{staticClass:"btnnext"},[_vm._v("下一步")])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }