/* eslint-disable */
import { saveAs } from 'file-saver'
import XLSX from 'xlsx-style'

function generateArray(table) {
  var out = [];
  var rows = table.querySelectorAll('tr');
  var ranges = [];
  for (var R = 0; R < rows.length; ++R) {
    var outRow = [];
    var row = rows[R];
    var columns = row.querySelectorAll('td');
    for (var C = 0; C < columns.length; ++C) {
      var cell = columns[C];
      var colspan = cell.getAttribute('colspan');
      var rowspan = cell.getAttribute('rowspan');
      var cellValue = cell.innerText;
      if (cellValue !== "" && cellValue == +cellValue) cellValue = +cellValue;

      //Skip ranges
      ranges.forEach(function (range) {
        if (R >= range.s.r && R <= range.e.r && outRow.length >= range.s.c && outRow.length <= range.e.c) {
          for (var i = 0; i <= range.e.c - range.s.c; ++i) outRow.push(null);
        }
      });

      //Handle Row Span
      if (rowspan || colspan) {
        rowspan = rowspan || 1;
        colspan = colspan || 1;
        ranges.push({
          s: {
            r: R,
            c: outRow.length
          },
          e: {
            r: R + rowspan - 1,
            c: outRow.length + colspan - 1
          }
        });
      };

      //Handle Value
      outRow.push(cellValue !== "" ? cellValue : null);

      //Handle Colspan
      if (colspan)
        for (var k = 0; k < colspan - 1; ++k) outRow.push(null);
    }
    out.push(outRow);
  }
  return [out, ranges];
};

function datenum(v, date1904) {
  if (date1904) v += 1462;
  var epoch = Date.parse(v);
  return (epoch - new Date(Date.UTC(1899, 11, 30))) / (24 * 60 * 60 * 1000);
}

function sheet_from_array_of_arrays(data, opts) {
  var ws = {};
  var range = {
    s: {
      c: 10000000,
      r: 10000000
    },
    e: {
      c: 0,
      r: 0
    }
  };
  for (var R = 0; R != data.length; ++R) {
    for (var C = 0; C != data[R].length; ++C) {
      if (range.s.r > R) range.s.r = R;
      if (range.s.c > C) range.s.c = C;
      if (range.e.r < R) range.e.r = R;
      if (range.e.c < C) range.e.c = C;
      var cell = {
        v: data[R][C]
      };

      var cell_ref = XLSX.utils.encode_cell({
        c: C,
        r: R
      });
      if (cell.v == null) {
        cell.v = '';
        ws[cell_ref] = cell;
        continue;
      }
      if (typeof cell.v === 'number') cell.t = 'n';
      else if (typeof cell.v === 'boolean') cell.t = 'b';
      else if (cell.v instanceof Date) {
        cell.t = 'n';
        cell.z = XLSX.SSF._table[14];
        cell.v = datenum(cell.v);
      } else cell.t = 's';

      ws[cell_ref] = cell;
    }
  }
  if (range.s.c < 10000000) ws['!ref'] = XLSX.utils.encode_range(range);
  return ws;
}

function Workbook() {
  if (!(this instanceof Workbook)) return new Workbook();
  this.SheetNames = [];
  this.Sheets = {};
}

function s2ab(s) {
  var buf = new ArrayBuffer(s.length);
  var view = new Uint8Array(buf);
  for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
  return buf;
}

function formatJson(filterVal, jsonData) {
  return jsonData.map((v) =>
    filterVal.map((j) => {
      return v[j];
    })
  );
}

export function export_table_to_excel(id) {
  var theTable = document.getElementById(id);
  var oo = generateArray(theTable);
  var ranges = oo[1];

  /* original data */
  var data = oo[0];
  var ws_name = "Sheet";

  var wb = new Workbook(),

    ws = sheet_from_array_of_arrays(data);

  /* add ranges to worksheet */
  // ws['!cols'] = ['apple', 'banan'];
  ws['!merges'] = ranges;

  /* add worksheet to workbook */
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;

  var wbout = XLSX.write(wb, {
    bookType: 'xlsx',
    bookSST: false,
    type: 'binary'
  });

  saveAs(new Blob([s2ab(wbout)], {
    type: "application/octet-stream"
  }), "test.xlsx")
}

export function export_json_to_excel({
  title = [],
  multiHeader = [],
  header,
  data,
  filename,
  merges = [],
  style = [],
  border = false,
  autoWidth = true,
  useStyleWidth = false,
  bookType = 'xlsx'
} = {}) {
  /* original data */
  filename = filename || 'excel-list'
 let excelData = []
  if (title.length > 0) {
    excelData.push(title);
  }
  if(multiHeader){
    for (let i = 0; i < multiHeader.length; i++) {
      excelData.push(multiHeader[i])
    }
  }else{
    excelData.push(header);
  }
  excelData.push(...data)
 

  var ws_name = "Sheet";
  var wb = new Workbook(),
    ws = sheet_from_array_of_arrays(excelData);

  if (merges.length > 0) {
    if (!ws['!merges']) ws['!merges'] = [];
    merges.forEach(item => {
      ws['!merges'].push(XLSX.utils.decode_range(item))
    })
  }

  if (autoWidth) {
    /*设置worksheet每列的最大宽度*/
    const colWidth = excelData.map(row => row.map(val => {
      /*先判断是否为null/undefined*/
      if (val == null) {
        return {
          'wch': 10
        };
      }
      /*再判断是否为中文*/
      else if (val.toString().charCodeAt(0) > 255) {
        return {
          'wch': val.toString().length * 2 > 150 ? 150 : val.toString().length * 2
        };
      } else {
        return {
          'wch': val.toString().length > 150 ? 150 : val.toString().length
        };
      }
    }))
    /*以第一行为初始值*/
    let result = colWidth[0];
    // debugger
    for (let i = 1; i < colWidth.length; i++) {
      for (let j = 0; j < colWidth[i].length; j++) {
        if (result[j]['wch'] < colWidth[i][j]['wch']) {
          result[j]['wch'] = colWidth[i][j]['wch'];
        }
      }
    }
    ws['!cols'] = result;
  }

  if (useStyleWidth) {
    const colWidth = excelData.map(row => row.map(val => {
      return {
        'wch': 10
      };
    }))
    let result = colWidth[0];
    if (style.length > 0) {
      style.forEach(item => {
        result[item.index]['wch'] = item.width
      })
    }
    ws['!cols'] = result;
  }
  /* add worksheet to workbook */
  wb.SheetNames.push(ws_name);
  wb.Sheets[ws_name] = ws;
  var dataInfo = wb.Sheets[wb.SheetNames[0]];
  if (border) {
    const borderAll = {  //单元格外侧框线
      top: {
        style: 'thin'
      },
      bottom: {
        style: 'thin'
      },
      left: {
        style: 'thin'
      },
      right: {
        style: 'thin'
      }
    };
    //设置边框
    for (var i in dataInfo) {
      if (i == '!ref' || i == '!merges' || i == '!cols' || i == 'A1') {

      } else {
        dataInfo[i + ''].s = {
          border: borderAll
        }
      }
    }
  }
  //设置样式
  if (style.length > 0) {
    style.forEach(item => {
      if (dataInfo[item.key]) {
        dataInfo[item.key].s = item.style
      }
    })
  }

  var wbout = XLSX.write(wb, {
    bookType: bookType,
    bookSST: false,
    type: 'binary'
  });
  saveAs(new Blob([s2ab(wbout)], {
    type: "application/octet-stream"
  }), `${filename}.${bookType}`);
}

//生成excel里边的ABCD...Z AA...ZZ
export function getExcelLetters(colCount) {
  let max = 26 + 26 * 26;
  if (colCount > max) {
    return [];
  }

  let letters = [];
  for (var i = 65; i < 91; i++) {
    letters.push(String.fromCharCode(i));
  }

  if (colCount <= 26) {
    return letters;
  }
  var moreLetters = [];
  moreLetters = moreLetters.concat(letters);
  for (var i = 0; i < 26; i++) {
    let newArr = letters.map((item) => {
      var letter = letters[i] + item;
      return letter;
    })
    moreLetters = moreLetters.concat(newArr);
    if (moreLetters.length >= colCount) {
      return moreLetters;
    }
  }
}

//导出excel
export function exportExcel(theTitle, columns, tableData, fileName) {
  var list = tableData;
  if (list.length === 0 || !columns) {
    return;
  } 
  // debugger
  var colCount = columns.length;
  let letters = getExcelLetters(colCount);
  const tHeader = [];
  const filterVal = [];
 
  // style
  var border = {
    top: {
      style: "thin",
    },
    bottom: {
      style: "thin",
    },
    left: {
      style: "thin",
    },
    right: {
      style: "thin",
    },
  };
  var titleStyle = {
    font: {
      name: "黑体",
      sz: 12,
      color: { rgb: "000000" },
      bold: true,
      italic: false,
      underline: false,
    },
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
  };
  const style = [
    {
      key: "A1",
      style: titleStyle,
      index: 1,
    }
  ];
  var headerStyle = JSON.parse(JSON.stringify(titleStyle));
  headerStyle.border = border;
  for (let i = 0; i < colCount; i++) {
    style.push({
      key: letters[i] + "2",
      style: headerStyle,
      index: i,
      width: 17
    });
  }
  var contentStyle = JSON.parse(JSON.stringify(titleStyle));
  contentStyle.border = border;
  contentStyle.font.bold = false;
  contentStyle.font.name = "宋体";
  contentStyle.font.sz = 10;
  for (let j = 0; j < list.length; j++) {
    for (let i = 0; i < colCount; i++) {
      var index = j + 3;
      style.push({
        key: letters[i] + index,
        style: contentStyle,
        index: i,
        width: 17
      });
    }
  }

  columns.forEach((item) => {
    tHeader.push(item.title);
    filterVal.push(item.key);
  });

  const data = formatJson(filterVal, list);

  const title = [theTitle];
  for (let i = 1; i < colCount; i++) {
    title.push("");
  }
  // debugger
  export_json_to_excel({
    title,
    multiHeader:null,
    header: tHeader,
    data,
    filename: fileName,
    merges: [`A1:${letters[colCount - 1]}1`],
    style: style,
    autoWidth: false,
    useStyleWidth: true,
    bookType: "xlsx",
  });
}

// 生成统一样式
export function formatStyle(tableData,diffNum,colNum){
  let list = tableData;
  if (list.length === 0) {
    return;
  }
  let colCount = colNum ? colNum:Object.keys(list[0]).length;
  let letters = getExcelLetters(colCount);
  const border = {
    top: {
      style: "thin",
    },
    bottom: {
      style: "thin",
    },
    left: {
      style: "thin",
    },
    right: {
      style: "thin",
    },
  };
  let titleStyle = {
    font: {
      name: "黑体",
      sz: 12,
      color: { rgb: "000000" },
      bold: true,
      italic: false,
      underline: false,
    },
    alignment: {
      horizontal: "center",
      vertical: "center",
    },
  };
  const style = [
    {
      key: "A1",
      style: titleStyle,
      index: 1,
    }
  ];
  var headerStyle = JSON.parse(JSON.stringify(titleStyle));
  headerStyle.border = border;
  for (let i = 0; i < colCount; i++) {
    style.push({
      key: letters[i] + "2",
      style: headerStyle,
      index: i,
      width: 17
    });
  }
  var contentStyle = JSON.parse(JSON.stringify(titleStyle));
  contentStyle.border = border;
  contentStyle.font.bold = false;
  contentStyle.font.name = "宋体";
  contentStyle.font.sz = 10;
  for (let j = 0; j < list.length+(diffNum ? diffNum : 0); j++) {
    for (let i = 0; i < colCount; i++) {
      var index = j + 3;
      style.push({
        key: letters[i] + index,
        style: contentStyle,
        index: i,
        width: 17
      });
    }
  }
  return style
}