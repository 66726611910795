//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import * as API from "@/api/apiService.js";
import InputTemplate from "../components/QuestionList/InputTemplate.vue";
import DateTemplate from "../components/QuestionList/DateTemplate.vue";
import SelectTemplate from "../components/QuestionList/SelectTemplate.vue";
import TextareaTemplate from "../components/QuestionList/TextareaTemplate.vue";
import { kefu } from "@/utils/udesk.js";
export default {
  components: { InputTemplate, DateTemplate, SelectTemplate, TextareaTemplate },
  data() {
    return {
      beginClaim: {},
      questionList: [],
      warrantyPlan: "",
      tit1eNumber: "",
      questionData: null,
      selectedCodeAnswer: [],
      visibleQuestionList: [],
      from: null,
      udeskParam: null,
    };
  },
  activated() {
    this.udeskParam = JSON.parse(window.sessionStorage.getItem("udeskParam"));
    kefu(this.udeskParam);
    this.beginClaim = JSON.parse(window.sessionStorage.getItem("beginClaim"));
    this.warrantyPlan = JSON.parse(
      window.sessionStorage.getItem("warrantyPlan")
    );

    if (
      this.from == "ComfirmInfo" ||
      this.from == "UploadFile" ||
      this.from == null
    ) {
      var param = this.beginClaim;
      Object.assign(this.$data, this.$options.data());
      this.getQuestionList(param);
    } else {
      this.questionData = JSON.parse(
        window.sessionStorage.getItem("questionData")
      );
      this.questionList = this.questionData.questions;
      for (let i = 0; i < this.questionList.length; i++) {
        const element = this.questionList[i];
        if (element.preConditions.length > 0) {
          const conditions = element.preConditions[0];
          element.conditionObj = {
            code: conditions.questionCode,
            codeanswer: conditions.questionCode + "-" + conditions.answerCode,
          };
          element.preConditionType = conditions.preConditionType;
        }
      }
    }
    this.getTitle();
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      //  这里的vm指的就是vue实例，可以用来当做this使用
      console.log(to);
      console.log(from.name);
      vm.from = from.name;
    });
  },
  methods: {
    goHelp() {
      window.sessionStorage.setItem(
        "questionData",
        JSON.stringify(this.questionData)
      );
      this.$router.push({ name: "CommonFaultHelp" });
    },
    getQuestionList(param) {
      this.udeskParam = JSON.parse(window.sessionStorage.getItem("udeskParam"));
      kefu(this.udeskParam);
      this.beginClaim = JSON.parse(window.sessionStorage.getItem("beginClaim"));
      this.warrantyPlan = JSON.parse(
        window.sessionStorage.getItem("warrantyPlan")
      );
      API.submitdevice(param).then((res) => {
        this.questionData = res;
        this.questionList = res.questions;
        for (let i = 0; i < this.questionList.length; i++) {
          const element = this.questionList[i];
          if (element.preConditions.length > 0) {
            const conditions = element.preConditions[0];
            element.conditionObj = {
              code: conditions.questionCode,
              codeanswer: conditions.questionCode + "-" + conditions.answerCode,
            };
            element.preConditionType = conditions.preConditionType;
          }
        }
      });
    },
    isShow(obj, type, item) {
      var res = false;
      if (!obj) {
        res = true;
        //
      } else {
        var codeList = [];
        var codeItem = {};
        this.selectedCodeAnswer.forEach((element) => {
          codeItem.code = element.code;
          codeList.push(codeItem);
        });

        var codeObj = { code: obj.code };
        for (var i = 0; i < this.selectedCodeAnswer.length; i++) {
          if (type == 0) {
            if (
              JSON.stringify(this.selectedCodeAnswer[i]).indexOf(
                JSON.stringify(obj)
              ) != -1
            ) {
              res = true;
            }
          } else {
            for (var a = 0; a < codeList.length; a++) {
              if (type == 1) {
                if (
                  JSON.stringify(codeList[a]).indexOf(
                    JSON.stringify(codeObj)
                  ) != -1 //有对应的code
                ) {
                  if (
                    JSON.stringify(this.selectedCodeAnswer[i]).indexOf(
                      JSON.stringify(obj)
                    ) == -1 //没有对应的答案
                  ) {
                    res = true;
                  } else {
                    res = false;
                  }
                }
              } else if (type == 2) {
                if (
                  JSON.stringify(codeList[a]).indexOf(
                    JSON.stringify(codeObj)
                  ) != -1 //有对应的code
                ) {
                  res = true;
                }
              }
            }
          }
        }
      }
      if (res) {
        item.visiable = true;
        return true;
      } else {
        item.visiable = false;
        for (let i = 0; i < this.questionList.length; i++) {
          var element = this.questionList[i];
          if (!element.visiable) {
            //问题可视化
            if (element.answerType == 0 || element.answerType == 3) {
              //下拉框操作
              if (element.answer.answer.code !== null) {
                element.answer.answer.code = null;
                element.answer.answer.sequenceNumber = 0;
                element.answer.answer.text = null;
              }
            } else {
              //input,textarea,date操作
              if (element.answer.answer !== null) {
                element.answer.answer = null;
              }
            }
          }
        }
        return false;
      }
    },
    next() {
      for (let i = 0; i < this.questionList.length; i++) {
        let vertify = true;
        let massage = "";
        const element = this.questionList[i];
        if (element.visiable) {
          if (element.mandatory == true) {
            if (element.answerType == 0 || element.answerType == 3) {
              //下拉框
              if (
                element.answer.answer.code == null ||
                element.answer.answer.code == ""
              ) {
                massage = "请补全'" + element.text + "'问题的答案";
                vertify = false;
              } else {
                vertify = true;
              }
            } else {
              //input,textarea,date
              if (
                element.answer.answer == null ||
                element.answer.answer == ""
              ) {
                massage = "请补全'" + element.text + "'问题的答案";
                vertify = false;
              } else {
                vertify = true;
              }
            }
          }
        } else {
          if (element.answerType == 0 || element.answerType == 3) {
            //下拉框
            if (element.answer.answer.code !== null) {
              element.answer.answer.code = null;
              element.answer.answer.sequenceNumber = 0;
              element.answer.answer.text = null;
            }
          } else {
            //input,textarea,date
            if (element.answer.answer !== null) {
              element.answer.answer = null;
            }
          }
        }
        if (!vertify) {
          this.$message.warning(massage);
          return false;
        }
      }

      this.questionData.questions = this.questionList;
      window.sessionStorage.setItem(
        "questionData",
        JSON.stringify(this.questionData)
      );
      this.$router.push({ name: "AddressInfo" });
    },
    changeInputanswer(val, item) {
      item.answer.answer = val;
    },
    changeSelectanswer(val, item) {
      var selectedAnwserItem = {
        code: item.code,
        codeanswer: item.code + "-" + val,
      };
      if (this.selectedCodeAnswer.length < 1) {
        this.selectedCodeAnswer.push(selectedAnwserItem);
      } else {
        this.selectedCodeAnswer.forEach((element, index) => {
          if (element.code == item.code) {
            this.selectedCodeAnswer.splice(index);
          }
          this.selectedCodeAnswer.push(selectedAnwserItem);
        });
      }

      var list = item.answer.answers;
      list.forEach((element) => {
        if (val === element.code) {
          item.answer.answer = JSON.parse(JSON.stringify(element));
        }
      });
    },

    changeDateanswer(val, item) {
      item.answer.answer = val;
    },
    changeTextanswer(val, item) {
      item.answer.answer = val;
    },
    getTitle() {
      if (
        //存量机申请理赔需要四步，铂金计划，金计划和银计划申请理赔不需要上传申请材料，只需要三步
        this.warrantyPlan === "Existing subscriber plan"
      ) {
        this.tit1eNumber = "3/4";
      } else if (
        this.warrantyPlan === "Platinum" ||
        this.warrantyPlan === "Silver" ||
        this.warrantyPlan === "Gold" ||
        this.warrantyPlan === ""
      ) {
        this.tit1eNumber = "2/3";
      } else {
        this.tit1eNumber = "2/3";
      }
    },
    contactKefu() {
      var param = {
        userName: JSON.parse(window.sessionStorage.getItem("phone")),
        type: 4,
        policyNumber: this.udeskParam.certificateNumber,
        claimNumber: "",
      };
      API.addLog(param).then((res) => {
        console.log(res);
      });
    },
  },
};
